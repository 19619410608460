import './App.css';
import AppRouter from './config/AppRouter';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
