import React from "react";
import { Header } from "../../components";

const Photography = () => {
  return (
    <div className="development-Landing-page">
      <Header />
    </div>
  );
};

export default Photography;
