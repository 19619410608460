import wistful from '../assets/images/wistful.jpg'
import joy from '../assets/images/joy.jpg'
import tension from '../assets/images/relibit.png'
// import tenderness from '../assets/images/tenderness.jpg'
import tiredness from '../assets/images/tiredness.jpg'

export const linksList = [
    // {
    //     name: "AI Dev",
    //     description: "World’s first tech team on a subscription that gives you an entire team in minutes.",
    //     slogan: "Co-Founder",
    //     img: wistful,
    //     link: "https://ahamoment.co/"
    // },
    // {
    //     name: "Shopify Devs",
    //     description: "All-inclusive Shopify team: setup, custom coding, IT, and more, all for a flat monthly price.",
    //     slogan: "Co-Founder",
    //     img: joy,
    //     link: "https://shopifydevs.io/"
    // },
    {
        name: "Commerce Squad",
        description: "subscribe to a self-managed tech team for ecommerce.",
        slogan: "Co-Founder",
        img: wistful,
        link: "https://commercesquad.ai/"
    },
    {
        name: "League Ahead",
        description: "truly understand AI and ML",
        slogan: "Co-Founder",
        img: joy,
        link: "https://leagueahead.com/"
    },
    {
        name: "Reliable Bits",
        description: "no-contract tech team for startups and founders",
        slogan: "Co-Founder",
        img: tension,
        link: "https://reliablebits.io/"
    },
    // {
    //     name: "AI Course for Founders",
    //     description: "There isn’t an easy-to-follow course explaining AI. I’ve created the one course that covers concepts you must know in AI",
    //     slogan: "Creator",
    //     img: tenderness,
    //     link: "https://masterthefuture.ai/"
    // },
    {
        name: "Youtube",
        description: "content to help founders stay ahead of the curve",
        slogan: "Creator",
        img: tiredness,
        link: "https://youtube.com/c/deeptalkstv"
    },
]

export const bottomLinks = [
    {
        name: "Mercury",
        description: "",
        slogan: "Investment",
        showSloganOnMob: true,
        link:"https://mercury.com/"
    },
    {
        name: "Freja",
        description: "",
        slogan: "Investment",
        showSloganOnMob: true,
        link:"https://frejanyc.com/"
    },
    {
        name: "Aha Moment",
        description: "",
        slogan: "Investment",
        showSloganOnMob: true,
        link:"https://ahamoment.co/"
    },
]