import React, { useState } from 'react'
import websiteLogo from '../../assets/images/ddevlogo.svg'
import ListCard from '../listCard';
import './style.scss';
import { bottomLinks, linksList } from '../../utils/constants';


const Header = () => {

    const [listHovering, setListHovering] = useState(false);
    const [activeLink, setActiveLink] = useState(0);

    const [currentImage, setCurrentImage] = useState(linksList?.[0]?.img)

    const mouseOver = () => setListHovering(true);
    const mouseOut = () => setListHovering(false)

    return (
        <>
            <div className='img__container'>
                <img alt='' src={currentImage} />
            </div>
            <div className='photography__header__main'>
                <div className='logo__section'>
                    <img alt='' src={websiteLogo} />
                    <p>
                        Hey there, I’m Deepak Devjani. I’m a serial entrepreneur, educator, and investor. You could say I’m part human, part AI, since I’ve woven it into just about every part of my daily life.
                    </p>
                </div>
                <div className='links__list__section'>
                    <div onMouseOut={mouseOut} onMouseOver={mouseOver} className='list__section'>
                        <div onMouseOver={mouseOver} onMouseOut={mouseOut} className='links__list'>
                            {linksList?.map((v, i) => {
                                return <ListCard activeLink={activeLink} setActiveLink={setActiveLink} index={i} setCurrentImage={setCurrentImage} listHovering={listHovering} v={v} key={i} />
                            })}
                        </div>
                        <div className='second__card__list'>
                            {bottomLinks?.map((v, i) => {
                                return <ListCard activeLink={activeLink} setActiveLink={setActiveLink} index={linksList?.length + i} listHovering={listHovering} v={v} key={i} />
                            })}
                        </div>
                    </div>
                </div>
                <div className='close__icon__section'>
                </div>
            </div>
        </>
    )
}

export default Header