import React, { useState } from 'react'
import { ArrowRightIcon } from '../icons';
import { Link } from 'react-router-dom';

const ListCard = ({ v, listHovering, setCurrentImage, setActiveLink, index, activeLink }) => {


    const [hovered, setHovered] = useState(false);

    const isSmScreen = window.innerWidth < 900;

    const mouseOver = () => {
        setHovered(true);
        setCurrentImage && setCurrentImage(v?.img)
    }
    const mouseOut = () => setHovered(false);

    const handleLinkClick = () => {
        setActiveLink(index);
    };

    return (
        <>
            {isSmScreen ? <div>
                <button onClick={handleLinkClick} className={`list__item__btn ${index === activeLink ? "active__btn" : ""}`}>
                    <div className='name__section'>
                        {v?.name}
                    </div>
                    <div className='link__section'>
                        <Link target='_blank' to={v?.link}>{v?.showSloganOnMob ? v?.slogan : v?.description}{" "}<ArrowRightIcon /></Link>
                    </div>
                </button>
            </div> : <div className={`${listHovering ? "list_hover" : ""} ${hovered ? "active_list_item" : ""} list__item`}>
                <div className='slogan'>{v?.slogan}</div>
                <div onMouseOver={mouseOver} onMouseOut={mouseOut}>
                    <Link target='_blank' to={v?.link}>
                        {v?.name}
                    </Link>
                </div>
                <div className='description__section'>{v?.description}</div>
            </div>}
        </>
    )
}

export default ListCard